<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'AsaLoginForm',
    metaInfo: { title: 'ASA Login Form' },
    extends: View,
    mixins: [
      LoadSections([
        'asa-login-form',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'asa-login-form',
      },
    },
  }
</script>
